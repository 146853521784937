<template>
  <v-row>
    <v-col cols="12">
      <base-card>
        <v-card-title>
          <div>Events</div>
          <div class="event-filters d-flex justify-space-between">
            <v-text-field
              v-model="franchise"
              class="mx-2"
              append-icon="mdi-magnify"
              label="Franchise"
              outlined
              dense
              hide-details
            ></v-text-field>
            <v-text-field
              v-model="customer"
              class="mx-2"
              append-icon="mdi-magnify"
              label="Customer"
              outlined
              dense
              hide-details
            ></v-text-field>
            <v-text-field
              v-model="studentName"
              class="mx-2"
              append-icon="mdi-magnify"
              label="Student Name"
              outlined
              dense
              hide-details
            ></v-text-field>
            <v-text-field
              v-model="studentEmail"
              class="mx-2"
              append-icon="mdi-magnify"
              label="Student Email"
              outlined
              dense
              hide-details
            ></v-text-field>
            <v-select
              v-model="type"
              class="filter-input"
              :items="types"
              label="Type"
              clearable
              outlined
              dense
            ></v-select>
            <v-dialog ref="dialog" v-model="dialog" width="290px">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  class="filter-input"
                  v-model="dateRangeText"
                  label="Date range"
                  v-bind="attrs"
                  v-on="on"
                  @click:clear="dates = []"
                  clearable
                  outlined
                  readonly
                  dense
                ></v-text-field>
              </template>
              <v-date-picker v-model="dates" range scrollable>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="modal = false">
                  Cancel
                </v-btn>
                <v-btn text color="primary" @click="$refs.dialog.save(dates)">
                  OK
                </v-btn>
              </v-date-picker>
            </v-dialog>

            <v-btn class="ml-2" dark color="primary" @click="paginate">
              Filter
            </v-btn>
          </div>
        </v-card-title>
        <v-data-table
          item-key="id"
          class="elevation-1 table-one"
          :headers="headers"
          :items="getEmailLogs.length ? getEmailLogs : []"
          :page="getEmailLogsPage"
          :pageCount="getEmailLogsPagesNumber"
          :items-per-page="10"
          :options.sync="options"
          :server-items-length="getEmailLogsTotal"
          :loading="getEmailLogsLoading"
          :disable-sort="true"
          :disable-filtering="true"
        >
          <template v-slot:item.actions="{ item }">
            <div class="w-100 text-center">
              <v-btn icon class="ml-auto" @click="viewLog(item)">
                <v-icon dark>mdi-eye</v-icon>
              </v-btn>
            </div>
          </template>
        </v-data-table>
      </base-card>

      <v-dialog ref="viewDialog" v-model="viewDialog" width="600px">
        <v-card>
          <v-card-text class="pt-3">
            <v-row v-for="item in selected" :key="item.key">
              <v-col cols="2" class="text-right">{{ item.key }}</v-col>
              <v-col
                cols="10"
                v-if="item.key === 'Body'"
                class="text-dark"
                v-html="item.value"
              ></v-col>
              <v-col cols="10" class="text-dark" v-else>{{ item.value }}</v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="viewDialog = false"> Close </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
import { getTypes } from "@/services/emailLogService";
import _ from "lodash";
import { mapActions, mapGetters } from "vuex";

export default {
  metaInfo: {
    title: "Event",
  },
  data() {
    return {
      model: "event",
      page: null,
      franchise: null,
      customer: null,
      studentName: null,
      studentEmail: null,
      type: null,
      types: [],
      dates: [],
      selected: null,
      shouldRemoveModel: false,
      dialog: false,
      viewDialog: false,
      options: {},
      headers: [
        { text: "Franchise", value: "franchise" },
        { text: "Customer", value: "customer" },
        { text: "Student", value: "student" },
        { text: "Email", value: "email" },
        { text: "Subject", value: "subject" },
        { text: "Type", value: "type" },
        { text: "Created", value: "created_at" },
        { text: "", value: "actions" },
      ],
    };
  },
  mounted() {
    this.page = this.$store.getters.getPage({ model: this.model });
    this.getTypes();
  },
  methods: {
    ...mapActions(["setEmailLogs"]),
    async getTypes() {
      try {
        const { data } = await getTypes();
        this.types = data || [];
      } catch (error) {
        console.error(error);
      }
    },
    paginate() {
      const { page, itemsPerPage } = this.options;
      this.setEmailLogs({
        page,
        perPage: itemsPerPage,
        type: this.type,
        franchise: this.franchise,
        customer: this.customer,
        student_name: this.studentName,
        student_email: this.studentEmail,
        dates: this.sortedDates,
      });
    },
    viewLog(item) {
      this.selected = this.formattedLog(item);
      console.log({
        item,
        selected: this.selected,
      });
      this.viewDialog = true;
    },
    formattedLog(item) {
      const headers = [
        "Student",
        "Email",
        "Subject",
        "Body",
        "Type",
        "Created",
      ];

      const keyMap = {
        student: "Student",
        email: "Email",
        subject: "Subject",
        body: "Body",
        type: "Type",
        created_at: "Created",
      };

      return headers
        .filter((header) => Object.values(keyMap).includes(header))
        .map((header) => {
          const originalKey = Object.keys(keyMap).find(
            (k) => keyMap[k] === header
          );

          let value = item?.[originalKey] ?? "—";
          return { key: header, value };
        });
    },
  },
  computed: {
    ...mapGetters([
      "getEmailLogs",
      "getEmailLogsPage",
      "getEmailLogsTotal",
      "getEmailLogsPagesNumber",
      "getEmailLogsLoading",
    ]),
    dateRangeText: {
      get() {
        return this.dates ? this.dates.join(" ~ ") : "";
      },
      set(value) {
        return value;
      },
    },
    sortedDates() {
      const dates =
        _.size(this.dates) === 1
          ? [...this.dates, _.get(this.dates, "0")]
          : this.dates;

      return dates.slice().sort((a, b) => new Date(a) - new Date(b));
    },
  },
  watch: {
    options: {
      handler() {
        this.paginate();
      },
    },
    deep: true,
  },
};
</script>
<style lang="scss" scoped>
.full-width {
  width: 100%;
}

.event-filters {
  .filter-input {
    margin: 0 0.5rem;
    width: 12em;
  }

  ::v-deep .v-text-field__details {
    display: none;
  }
}

.text-dark {
  color: rgba(0, 0, 0, 0.87);
}

::v-deep .table-one {
  thead.v-data-table-header {
    tr {
      &:hover {
        background-color: #f2f3f8;
      }
      th {
        span {
          font-size: 16px;
          color: #304156;
        }
      }
    }
    tr {
      td {
        padding-bottom: 20px;
        padding-top: 20px;
      }
    }
  }
  tbody {
    tr {
      &:hover {
        background-color: #f2f3f8 !important;
      }

      .description {
        min-width: 120px;
      }

      td {
        &:nth-child(3) {
          word-break: break-word;
          word-wrap: break-word;

          div {
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
          }
        }
      }
    }
  }
}
</style>
